#collasible-nav-dropdown_dashboard_projects::after{
    display:none;
}

.ContentWrapper:has(.ticketsystem){
    background: none !important;
}


.teamMember{
   /* position: absolute  !important;;
    right: 55px;
    top: 24px;
    z-index: 0;*/
    margin-left: -10px !important;
    cursor: pointer;
}


.legende{
    margin-top: 20px;
    margin-right: 40px;
}

.totalPie{
    position: absolute;
    z-index: 9;
    margin-top: 45%;
    margin-left: 40%;
    text-align: center;
}
