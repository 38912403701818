#invoice-table {
    min-width: 900px;
}

@media (max-width: 1200px) {
    #invoice-table .input-group-text{
        padding-left: 0.1em;
        padding-right: 0.1em;
    }
    #invoice-table .form-control {
        padding-left: 0.1em;
        padding-right: 0.1em;
        min-width: 35px;
    }
    #invoice-table td {
        min-width: 75px;
        padding: 0.2rem
    }
    #invoice-table td.non-max-width {
        min-width: 10px;
    }


    #invoice-table th, #invoice-table td .form-control, #invoice-table td .form-select{
        font-size: 14px;
    }
}

@media (max-width: 1700px) {
    #invoice-table .input-group-text{
        padding-left: 0.3em;
        padding-right: 0.3em;
    }
    #invoice-table .form-control {
        padding-left: 0.3em;
        padding-right: 0.3em;
    }
    #invoice-table td {
        min-width: 90px;
    }
    #invoice-table td.non-max-width {
        min-width: 10px;
    }

    #invoice-table th, #invoice-table td .form-control, #invoice-table td .form-select{
        font-size: 14px;
    }
}