/*
Material Icon and Symbol Library
 */
@import url('material-icons/iconfont/material-icons.css');
@import url('material-symbols');


/*
Bootstrap 4
 */

@import url("css/bootstrap.min.css");

/*
Bootstrap 5
 */

@import url("css/bootstrap5.min.css.scss");

/*
Base css with all general settings
 */
@import url("css/base.css");

/*
Sidebar css with all style to sidebar
 */
@import url("css/sidebar.css");

/*
Footer css with all style to footer
 */
@import url("css/footer.css");

/*
Modal css with all style to modal
 */
@import url("css/modal.css");

/*
React Page CSS with all style to ract-page
 */
@import url("css/react-page.css");

/*
Scrollbar CSS with all style to scrollbar
 */
@import url("css/scrollbar.css");

/*
Tooltip CSS with all style to tooltip
 */
@import url("css/tooltip.css");

/*
Modul Masonry CSS with all style to masonry
 */
@import url("css/module/masonry.css");

/*
Modul Hexagon CSS with all style to hexagon
 */
@import url("css/module/hexagon.css");

/*
Unsortiertes CSS Muss noch zugeordnet werden
 */
@import url("css/unsort.css");

/*
Generic sizing classes
 */
@import url("css/sizing.css");


/*
Styling offer format
 */
@import url("./modules/CMS/offer/css/style.css");

/*
Styling offer format
 */
@import url("./modules/CMS/billing/css/style.css");


.pro-sidebar-content{
    background: linear-gradient(180deg,#135e7e,#103b5b 35%,#015b91);
}

.MenuItem{
    color:black !important;
}

.first{
    color:white !important;
}
body {
    background-image: url('/public/Wum-office.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.Login{
    margin-top: 12%;
    margin-right: 35%;
}

.DC_Title{
    min-height:65px !important;
}

.Login {

    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 50%, rgba(184,184,184,1) 100%);
}
.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 999999999;
    left: 245px;
    margin-top: -30px;
}

.tooltips:hover .tooltiptext {
    visibility: visible;
}

.tooltips{
    position: absolute;
    margin-top: -10px;
    z-index: 1007;
}

/* The Modal (background) */
.modal-tooltip {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999999999999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    left: 0;
}

/* Modal Header */
.modal-header-tooltip {
    padding: 2px 16px;
    background: #015b91;
    color: white;
}

/* Modal Body */
.modal-body-tooltip {padding: 2px 16px;}

/* Modal Footer */
.modal-footer-tooltip {
    padding: 2px 16px;
    background: #015b91;
    color: white;
}

/* Modal Content */
.modal-content-tooltip {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
    left: 5%;
}

/* Add Animation */
@keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}

.btn-primary{
    background-color: #015b91;
    border-color: #015b91;
    color:white;
}

.tooltips .material-icons {
    color: #015b91;
}

.pro-sidebar .pro-menu .pro-menu-item.active{
    background-color: #a2d9f7;
    border-color: #a2d9f7;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #a2d9f7;
}
.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item:hover{
    color: #015b91;
}
.col .ga-item img, .ga-item img {
    object-fit: scale-down;
}

.colMax{
    max-width: 270px !important;
}
.theme-footer p,.theme-header p{
    margin-right: 15px;
}

.formControl{
    background-color: white;
    padding:10px;
}
.pro-sidebar-content{
    background: linear-gradient(180deg,#135e7e,#103b5b 35%,#015b91);
}

.MenuItem{
    color:black !important;
}

.tooltips .material-icons {
    color: #015b91;
}

.pro-sidebar .pro-menu .pro-menu-item.active{
    background-color: #a2d9f7;
    border-color: #a2d9f7;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #a2d9f7;
}
.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item:hover{
    color: #015b91;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow-y: hidden;
}

.pro-sidebar-content{
    overflow-y: scroll;
}
.css-1wvake5{
    color: #adadad;
    height: 100%;
    min-width: 270px;
    position: relative;
    text-align: left;
    transition: width,left,right,.3s;
    width: 270px;
    z-index: 1009;
    border-right-width: unset!important;
    border-right-style: unset!important;
    border-color: unset!important;
}

.sidebarFooter {
    /* position: absolute;
     bottom: 0;*/
}
.css-dip3t8 {
    overflow-y: hidden !important;
}
.SidebarContent
{
    background: linear-gradient(180deg,#135e7e,#103b5b 35%,#015b91) !important;
    height: 88%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.activeSidebar{
    background-color: #a2d9f7;
    border-color: #a2d9f7;
}

.deactiveSidebar .ps-menu-button{
    background-color: unset!important;
    border-color: unset!important;
}
.ps-menu-button:hover{
    background-color: #a2d9f7!important;
    border-color: #a2d9f7!important;
}
.sidebarHeader {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
}

.css-dip3t8{
    background-color: transparent!important;
}
.SidebarLogo {
    max-width: unset!important;
}

.form-control.h4 {
    font-size: 1.5rem;
    height: 100%;
}

.StarButton{
    background: linear-gradient(180deg,#135e7e,#103b5b 35%,#015b91);
    border: none;
    border-radius: 3px 0 0 3px;
    box-shadow: 0 5px 16px 0 rgba(0,0,0,.35);
    color: yellow !important;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 15px;
    position: fixed;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .4s cubic-bezier(.55,0,.1,1);
    z-index: 10000;
}

.box::after {
    content: "";
    height: 4px;
    width: 100%;
    display: block;
    margin-top: -23px;
    background: transparent;
    border-radius: 4px;
}