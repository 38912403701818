#offer-table {
    min-width: 900px;
}

@media (max-width: 1200px) {
    #offer-table .input-group-text{
        padding-left: 0.1em;
        padding-right: 0.1em;
    }
    #offer-table .form-control {
        padding-left: 0.1em;
        padding-right: 0.1em;
        min-width: 35px;
    }
    #offer-table td {
        min-width: 75px;
        padding: 0.2rem
    }
    #offer-table td.non-max-width {
        min-width: 10px;
    }


    #offer-table th, #offer-table td .form-control, #offer-table td .form-select{
        font-size: 14px;
    }
}

@media (max-width: 1700px) {
    #offer-table .input-group-text{
        padding-left: 0.3em;
        padding-right: 0.3em;
    }
    #offer-table .form-control {
        padding-left: 0.3em;
        padding-right: 0.3em;
    }
    #offer-table td {
        min-width: 90px;
    }
    #offer-table td.non-max-width {
        min-width: 10px;
    }

    #offer-table th, #offer-table td .form-control, #offer-table td .form-select{
        font-size: 14px;
    }
}